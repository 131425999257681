import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  password: boolean;
  loginForm: FormGroup;
  forgotForm: FormGroup;
  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private storage: StorageService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required])),
      password: new FormControl('', Validators.compose([Validators.required]))
    })

    this.forgotForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),

    });
  }

  ngOnInit(): void {
  }
  showPassword() {
    this.password = !this.password;
  }

  login() {
    this.commonService.presentSpinner();
    this.authService.login(this.loginForm.value).then(
      (res) => {
        if (res && res.AdminData) {
          this.commonService.showSuccessToastMsg('', 'Logged in successfully!');
          this.storage.set(Storage_Keys.admin_data, res.AdminData);
          let token = {};
          token[Storage_Keys.token] = res.AdminData.authToken
          this.storage.set(Storage_Keys.token, token);
          this.router.navigate(['admin/dashboard']);
        }
      }
    ).catch(
      (error) => {

      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    )
  }

  openResetPasswordModal() {
    $(".editModal").modal('show');
  }

  forgotpasswormsg: boolean;
  resetPassword() {
    this.commonService.presentSpinner();
    this.authService.forgottPassword(this.forgotForm.value).then(
      (res: any) => {
        if (res.code == 200) {
          $(".editModal").modal('hide');
          this.commonService.showSuccessToastMsg(res.message)
        }
      }
    ).catch((error) => {
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {
      this.commonService.dismissSpinner();
    })
  }
}
