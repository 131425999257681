import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  password: boolean;
  confirmpassword: boolean;
  token: any;
  ResetPasswordForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private commonSerivce: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token
    });
  }
  get f() { return this.ResetPasswordForm.controls; }
  ngOnInit(): void {
    this.ResetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    })
  }
  showPassword() {
    this.password = !this.password;
  }
  showConfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }

  ResetPassword() {
    this.submitted = true;
    if (this.ResetPasswordForm.invalid) {
      return;
    }

    this.commonSerivce.presentSpinner();
    let postdata = {
      "password": this.ResetPasswordForm.value.password,
      "confirmPassword": this.ResetPasswordForm.value.confirmpassword,
      "token": this.token,
    }

    this.auth.resetPassword(postdata).then(
      (res: any) => {
        if (res.code === 200) {
          this.ResetPasswordForm.reset();
          this.submitted = false;
          this.router.navigate(['/']);
          this.commonSerivce.showSuccessToastMsg(res.message);
        }
      }
    ).catch((error) => {
      this.commonSerivce.showErrorToastMsg(error.error.message);
    }).finally(() => {
      this.commonSerivce.dismissSpinner();
    })
  }
}
