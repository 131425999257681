// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  baseUrl: "https://api.test.app.spacejammit.com/admin/v1",

  s3: {
    "accessKeyId": "AKIA2BK3D3IM6K7ZVNQT",
    "secretAccessKey": "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",
    "region": "",
    "Bucket": "spacejammit"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
