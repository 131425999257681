import { Component } from '@angular/core';
import { UsersService } from './components/admin/services/users/users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'space-jammit';

  constructor() {
  }
}
