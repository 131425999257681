export const Endpoints = {
    "login": "/login",
    "logout": "/logout",
    "changePassword": "/change-password",
    "forgotpassword": "/forgot-password",
    "resetpassword": "/reset-password",
    "users": "/users",
    "changeStatus": (user_id: string) => { return `/user/change-status/${user_id}` },
    "userDetail": (user_id: string) => { return `/user/${user_id}` },
    "deleteUser": (user_id: string) => { return `/user/${user_id}` },
    "changeUserPassword": (user_id: string) => { return `/user/change-password/${user_id}` },
    // "spaceusedfilter": "/users",

    spaceusedfilter: (page: string, minValue: number, maxValue: number) => { return `/users?page=` + page + `&limit=10` + `&min_space_used=${minValue}` + (maxValue ? `&max_space_used=${maxValue}` : '') },
    // USer graph

    "usergraph": "/users/stats",
    "spacegraph": "/space/stats",

    "addVideo": "/video",
    "allVideos": "/video",
    "deleteVideo": (video_id: string) => { return `/video/${video_id}` },
    "editVideo": (video_id: string) => { return `/video/${video_id}` },
    "changeorder": "/video/change-order",

    "content": "/content",

    "message": "/message",

    "archiveImage": "/archive-image",
    "dashboard": "/dashboard",

    "addspacefacts": "/space-fact",
    "allmessage": "/space-fact",
    "editmessage": (messageId: string) => { return `/space-fact/${messageId}` },
    "deletemessage": (messageId: string) => { return `/space-fact/${messageId}` },


    "questionupdate": (questionid: string) => { return `/questions/${questionid}` },
    "questionslist": "/questions",

    "removedFiles": "/removedFiles",
    "deletedFiles": (fileid: string) => { return `/removedFiles/${fileid}` },

    "transactions": "/transactions"

}