<div class="auth-body-bg">
  <div class="home-btn d-none d-sm-block"> <a><i class="mdi mdi-home-variant h2 text-white"></i></a>
  </div>
  <div>
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div class="col-lg-4">
          <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div class="w-100">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div>
                    <div class="text-center">
                      <div>
                        <a class="logo">
                          <img src="assets/images/logo-light.png" height="100" alt="logo">
                        </a>
                      </div>
                      <h4 class="font-size-18 mt-3">Welcome to Login</h4>
                    </div>
                    <div class="p-2 mt-3">
                      <form class="form-horizontal" [formGroup]="loginForm">
                        <div class=" mb-4">
                          <div class="form-group auth-form-group-custom mb-0"> <i
                              class="ri-user-2-line auti-custom-input-icon"></i>
                            <label for="username">Username</label>
                            <input type="text" class="form-control" id="username" placeholder="Enter username"
                              formControlName="email">
                          </div>
                          <div class="invalid-feedback" style="display:none;">
                            <div>Username is required</div>
                          </div>
                        </div>
                        <div class=" mb-4">
                          <div class="form-group auth-form-group-custom mb-0"> <i
                              class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Password</label>
                            <input [type]="password ? 'text' : 'password' " class="form-control"
                              placeholder="Enter password" autocomplete="off" formControlName="password">
                            <i (click)="showPassword()" class=" auti-custom-input-icon auti-custom-eye"
                              [ngClass]="password ? 'ri-eye-fill' : 'ri-eye-line'"></i>
                          </div>
                          <div class="invalid-feedback" style="display:none;">
                            <div>Password is required</div>
                          </div>
                        </div>
                        <div class="custom-control custom-checkbox" style="display: none;">
                          <input type="checkbox" class="custom-control-input" id="customControlInline">
                          <label class="custom-control-label" for="customControlInline">Remember me</label>
                        </div>
                        <div class="mt-4 text-center">
                          <button class="btn btn-primary w-md waves-effect waves-light" [disabled]="loginForm?.invalid"
                            (click)="login()" type="submit">Log In</button>
                        </div>
                        <div class="mt-4 text-center">
                          <a class="text-muted" (click)="openResetPasswordModal()">
                            <i class="mdi mdi-lock mr-1"></i>
                            Forgot your password?
                          </a>
                          <!-- <div class="alert alert-success p-2 mt-2 mb-0 font-size-12" role="alert">An email has been
                            sent to the registered email id for password reset.</div> -->
                        </div>
                      </form>
                    </div>
                    <div class="mt-3 text-center  copyright">
                      <p>© 2021 Space Jammit Crafted with <i class="mdi mdi-heart text-danger"></i> by <a
                          href="https://greychaindesign.com/">Grey Chain</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="authentication-bg">
            <div class="bg-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade editModal" id="update-user" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-0">Recover your password</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-3">Please enter your email address that you logged in with us, A temporary password will be sent on
          your registered email
          address</p>
        <form class="form-theme" [formGroup]="forgotForm">
          <div class="mb-3">
            <input type="email" name="email" formControlName="email" class="form-control"
              placeholder="Enter your email address">
          </div>
          <div class="text-center">
            <button type="button" class="btn btn-block btn-block btn-dark btn-auth mb-3"
              [disabled]="forgotForm?.controls['email']?.invalid" (click)="resetPassword()">
              PROCEED</button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>